@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Cera Reg";
    src: url("./assets/fonts/Cera\ Pro\ Light.otf");
}
@font-face {
    font-family: "Cera Bold";
    src: url("./assets/fonts/Cera\ Pro\ Bold.otf");
}
@font-face {
    font-family: "Cera Italic";
    src: url("./assets/fonts/Cera\ Pro\ Regular\ Italic.otf");
}
@font-face {
    font-family: "Cera Mid";
    src: url("./assets/fonts/Cera\ Pro\ Medium.otf");
}

@font-face {
    font-family: "Quicksand Bold";
    src: url("./assets/fonts/Quicksand_Bold.otf");
}

@layer base {
  
    h1,h2,h3,h4 {
      @apply font-ceraMid
    }
  }
