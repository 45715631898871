.topbar ul .active {
  background-color: white;
}

.topbar ul .active a{
  color: #366EB6;
  font-weight: bold;
}

.loadingCont{
  height: calc(100vh - 100px);
  width: full;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading{
  height: 300px;
  width: 300px;
  border-radius: 50%;
  background: #007ee4;
  border: 5px solid #007ee4;
  position: relative;
  overflow: hidden;
}

.loading::after,
.loading::before{
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: 50%;
  background: white;
}

.loading::after{
  border-radius: 45%;
  animation: water 4s linear infinite;
}

.loading::before{
  border-radius: 35%;
  background: #0095e7;
  animation: water 4s linear infinite;
}

@keyframes water {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    top: -30%;
  }  
  50% {
    top: -60%;
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
    top: -30%;
  }  
}